import React, { useContext, useState, useEffect } from "react";
import { ProductContext } from "../Context/cartContext";
import { FaTrash } from "react-icons/fa";
import "../Styles/CartPage.css";
import {
  Modal,
  Button,
  DatePicker,
  Select,
  Input,
  message,
  InputNumber,
} from "antd";
import moment from "moment";
import { db, storage } from "../firebase/index";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { FormContext } from "../Context/FormContext"; // Import FormContext
import axios from "axios"; // Import axios for making HTTP requests

const { Option } = Select;
const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const minDate = moment().subtract(1, "years"); // Set minDate to one year ago

function CartPage() {
  const today = new Date();

  const navigate = useNavigate(); // Get the navigate function
  const { formData, updateFormData, setFormData } = useContext(FormContext); // Use FormContext

  const [selectedDay, setSelectedDay] = useState(null);

  const { selectedProducts, removeProductFromCart, setSelectedProducts } =
    useContext(ProductContext);
  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [beforefromDate, setBeforeFromDate] = useState(null);
  const [beforetoDate, setBeforeToDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0); // State to hold total amount
  const [deliverySlots, setDeliverySlots] = useState([]); // State to hold delivery slots
  const [betweenDates, setBetweenDates] = useState([]); // State to hold delivery slots
  const [daysOnce, setDaysOnce] = useState(0);
  const [paymentId, setPaymentId] = useState("");
  const [RazorpayLoaded, setRazorpayLoaded] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleChange = (value) => {
    setSelectedDay(value);
  };

  const handleSubscriptionSelect = (type) => {
    setBeforeFromDate(null);
    setBeforeToDate(null);
    console.log("come");
    setSelectedSubscription(type);
    console.log(fromDate, toDate, selectedTimeSlot, daysOnce, selectedDay);
    toggleModal(); // Open the modal when subscription type is selected
  };

  const handleRemoveFromCart = (productId) => {
    removeProductFromCart(productId);
  };

  useEffect(() => {
    calculateTotalAmount();
    fetchSlot();
  }, [selectedProducts]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => setRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const calculateTotalAmount = () => {
    console.log(selectedProducts);
    let total = 0;
    selectedProducts.forEach((product) => {
      if (product.variations.length > 0) {
        total += parseInt(product.variation_price);
      } else {
        total += parseInt(product.product_price);
      }
    });
    console.log("Total:", total);
    setTotalAmount(total);
  };

  const fetchSlot = () => {
    const docRef = db.collection("deliverySlots").doc("uIiD5MznbkA2vLPBOfDg");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const slots = data.slots || [];
          setDeliverySlots(slots); // Set the fetched delivery slots in state
        } else {
          console.log("Document not found!");
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
      });
  };

  const getModalWidth = () => {
    return window.innerWidth <= 768 ? "90%" : "70%";
  };

  const validateFormData = () => {
    const { email, phoneNumber } = formData;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!emailPattern.test(email)) {
      message.error("Invalid email address.");
      return false;
    }

    if (!phonePattern.test(phoneNumber)) {
      message.error("Invalid phone number. It should be 10 digits.");
      return false;
    }

    return true;
  };

  // const startPayment = async (e) => {
  //   e.preventDefault(); // Prevent default form submission

  //   // Validate subscription-related fields
  //   if (
  //     selectedSubscription === "daily" ||
  //     selectedSubscription === "weekly" ||
  //     selectedSubscription === "routinely"
  //   ) {
  //     if (!fromDate || !toDate) {
  //       message.error("Please select a from and to date.");
  //       return;
  //     }
  //     if (selectedSubscription === "weekly" && !selectedDay) {
  //       message.error("Please select a day.");
  //       return;
  //     }
  //     if (selectedSubscription === "routinely" && !daysOnce) {
  //       message.error("Please select a dayOnce.");
  //       return;
  //     }
  //   }

  //   if (!selectedTimeSlot) {
  //     message.error("Please select a time slot.");
  //     return;
  //   }

  //   if (!selectedPayment) {
  //     message.error("Please select a payment mode.");
  //     return;
  //   }

  //   if (totalAmount < 100) {
  //     message.error("Minimum order amount should be 100.");
  //     return;
  //   }

  //   if (!validateFormData()) {
  //     return;
  //   }

  //   const paymentMethod = selectedPayment === "online" ? "bacs" : "cod";
  //   const paymentMethodTitle =
  //     selectedPayment === "online" ? "Bank Transfer" : "Cash on Delivery";

  //   let orderData = {
  //     payment_method: paymentMethod,
  //     payment_method_title: paymentMethodTitle,
  //     set_paid: selectedPayment === "online",
  //     billing: {
  //       first_name: formData.firstName,
  //       last_name: formData.lastName,
  //       address_1: formData.streetAddress,
  //       city: formData.city,
  //       state: formData.state,
  //       postcode: formData.postcode,
  //       country: formData.country,
  //       email: formData.email,
  //       phone: formData.phoneNumber,
  //     },
  //     shipping: {
  //       first_name: formData.firstName,
  //       last_name: formData.lastName,
  //       address_1: formData.streetAddress,
  //       city: formData.city,
  //       state: formData.state,
  //       postcode: formData.postcode,
  //       country: formData.country,
  //     },
  //     line_items: selectedProducts.map((product) => ({
  //       product_id: product.product_id,
  //       quantity: 1,
  //       name:
  //         product.variations.length > 0
  //           ? product.variation_name
  //           : product.product_name,
  //       images: [product.product_image],
  //       price:
  //         product.variations.length > 0
  //           ? product.variation_price
  //           : product.product_price,
  //       category: product.main_category,
  //       sub_category: product.sub_category,
  //     })),
  //     deliveryDates: betweenDates,
  //     timeslot: selectedTimeSlot,
  //     totalAmount: totalAmount * betweenDates.length,
  //     orderDate: new Date().toISOString(),
  //   };

  //   console.log("orderData", orderData);

  //   if (selectedPayment === "online") {
  //     if (!RazorpayLoaded) {
  //       console.error("Razorpay script not loaded.");
  //       return;
  //     }

  //     const finalAmount = totalAmount * betweenDates.length;
  //     try {
  //       const options = {
  //         key: "rzp_live_7VBP1taJs9J9SB",
  //         amount: finalAmount * 100,
  //         currency: "INR",
  //         handler: async function (response) {
  //           try {
  //             await db.collection("subscriptions").add(orderData);
  //             message.success("Payment successful and order placed!");
  //             console.log("Subscription data saved to Firebase:", orderData);
  //             e.target.reset();
  //             setFormData({
  //               firstName: "",
  //               lastName: "",
  //               streetAddress: "",
  //               city: "",
  //               state: "",
  //               postcode: "",
  //               country: "",
  //               phoneNumber: "",
  //               email: "",
  //               orderNotes: "",
  //             });
  //             setSelectedProducts([]);
  //             setBeforeFromDate(null);
  //             setBeforeToDate(null);
  //             navigate("/");
  //           } catch (error) {
  //             console.error("Error saving order data:", error);
  //             message.error(
  //               "Payment successful, but there was an error saving your order. Please contact support."
  //             );
  //           }
  //         },
  //         prefill: {
  //           name: `${orderData.billing.first_name} ${orderData.billing.last_name}`,
  //           email: orderData.billing.email,
  //           contact: orderData.billing.phone,
  //         },
  //         theme: {
  //           color: "#3399cc",
  //         },
  //       };

  //       const rzp1 = new window.Razorpay(options);
  //       rzp1.open();
  //     } catch (error) {
  //       console.error("Error initiating payment:", error);
  //       message.error(
  //         "There was an error initiating the payment. Please try again."
  //       );
  //     }
  //   } else if (paymentMethod === "cod") {
  //     try {
  //       await db.collection("subscriptions").add(orderData);
  //       message.success("Order placed successfully with Cash on Delivery!");
  //       console.log("Subscription data saved to Firebase:", orderData);
  //       e.target.reset();
  //       setFormData({
  //         firstName: "",
  //         lastName: "",
  //         streetAddress: "",
  //         city: "",
  //         state: "",
  //         postcode: "",
  //         country: "",
  //         phoneNumber: "",
  //         email: "",
  //         orderNotes: "",
  //       });
  //       setSelectedProducts([]);
  //       setBeforeFromDate(null);
  //       setBeforeToDate(null);
  //       navigate("/");
  //     } catch (error) {
  //       console.error("Error saving order data:", error);
  //       message.error(
  //         "There was an error saving your order. Please contact support."
  //       );
  //     }
  //   }
  // };

  const startPayment = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Validate subscription-related fields
    if (
      selectedSubscription === "daily" ||
      selectedSubscription === "weekly" ||
      selectedSubscription === "routinely"
    ) {
      if (!fromDate || !toDate) {
        message.error("Please select a from and to date.");
        return;
      }
      if (selectedSubscription === "weekly" && !selectedDay) {
        message.error("Please select a day.");
        return;
      }
      if (selectedSubscription === "routinely" && !daysOnce) {
        message.error("Please select a dayOnce.");
        return;
      }
    }

    if (!selectedTimeSlot) {
      message.error("Please select a time slot.");
      return;
    }

    if (!selectedPayment) {
      message.error("Please select a payment mode.");
      return;
    }

    // if (totalAmount < 100) {
    //   message.error("Minimum order amount should be 100.");
    //   return;
    // }

    if (!validateFormData()) {
      return;
    }

    const paymentMethod = selectedPayment === "online" ? "bacs" : "cod";
    const paymentMethodTitle =
      selectedPayment === "online" ? "Bank Transfer" : "Cash on Delivery";

    let orderData = {
      payment_method: paymentMethod,
      payment_method_title: paymentMethodTitle,
      set_paid: selectedPayment === "online",
      billing: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address_1: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        postcode: formData.postcode,
        country: formData.country,
        email: formData.email,
        phone: formData.phoneNumber,
      },
      shipping: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address_1: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        postcode: formData.postcode,
        country: formData.country,
      },
      line_items: selectedProducts.map((product) => ({
        product_id: product.product_id,
        quantity: 1,
        name:
          product.variations.length > 0
            ? product.variation_name
            : product.product_name,
        images: [product.product_image],
        price:
          product.variations.length > 0
            ? product.variation_price
            : product.product_price,
        category: product.main_category,
        sub_category: product.sub_category,
      })),
      deliveryDates: betweenDates,
      timeslot: selectedTimeSlot,
      totalAmount: totalAmount * betweenDates.length,
      orderDate: new Date().toISOString(),
    };

    console.log("orderData", orderData);

    const createSubscriptionRiderOrders = async () => {
      const batch = db.batch();
      betweenDates.forEach((date, index) => {
        const riderOrderRef = db.collection("subscriptionRider").doc();
        const riderOrderData = {
          ...orderData,
          deliveryDates: date, // Only the current date
          totalAmount: totalAmount, // Amount for a single day
          parentOrderId: orderData.id, // Reference to the main subscription order
          orderIndex: index, // Index of this order in the subscription sequence
        };
        batch.set(riderOrderRef, riderOrderData);
      });
      await batch.commit();
    };

    if (selectedPayment === "online") {
      if (!RazorpayLoaded) {
        console.error("Razorpay script not loaded.");
        return;
      }

      const finalAmount = totalAmount * betweenDates.length;
      try {
        const options = {
          key: "rzp_live_7VBP1taJs9J9SB",
          amount: finalAmount * 100,
          currency: "INR",
          handler: async function (response) {
            try {
              const docRef = await db
                .collection("subscriptions")
                .add(orderData);
              orderData.id = docRef.id; // Add the document ID to orderData
              await createSubscriptionRiderOrders();
              message.success("Payment successful and orders placed!");
              console.log("Subscription data saved to Firebase:", orderData);
              e.target.reset();
              setFormData({
                firstName: "",
                lastName: "",
                streetAddress: "",
                city: "",
                state: "",
                postcode: "",
                country: "",
                phoneNumber: "",
                email: "",
                orderNotes: "",
              });
              setSelectedProducts([]);
              setBeforeFromDate(null);
              setBeforeToDate(null);
              navigate("/");
            } catch (error) {
              console.error("Error saving order data:", error);
              message.error(
                "Payment successful, but there was an error saving your order. Please contact support."
              );
            }
          },
          prefill: {
            name: `${orderData.billing.first_name} ${orderData.billing.last_name}`,
            email: orderData.billing.email,
            contact: orderData.billing.phone,
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } catch (error) {
        console.error("Error initiating payment:", error);
        message.error(
          "There was an error initiating the payment. Please try again."
        );
      }
    } else if (paymentMethod === "cod") {
      try {
        const docRef = await db.collection("subscriptions").add(orderData);
        orderData.id = docRef.id; // Add the document ID to orderData
        await createSubscriptionRiderOrders();
        message.success("Orders placed successfully with Cash on Delivery!");
        console.log("Subscription data saved to Firebase:", orderData);
        e.target.reset();
        setFormData({
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          phoneNumber: "",
          email: "",
          orderNotes: "",
        });
        setSelectedProducts([]);
        setBeforeFromDate(null);
        setBeforeToDate(null);
        navigate("/");
      } catch (error) {
        console.error("Error saving order data:", error);
        message.error(
          "There was an error saving your order. Please contact support."
        );
      }
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);

    if (!showModal) {
      setFromDate(null);
      setToDate(null);
      setBetweenDates([]);
    }
  };
  const handleDateChange = (date, type) => {
    if (type === "from") {
      setBeforeFromDate(date);
    } else if (type === "to") {
      setBeforeToDate(date);
    }

    const jsDate = date.toDate();

    const formattedDate = moment(jsDate).format("DD-MM-YYYY");

    if (type === "from") {
      setFromDate(formattedDate);
    } else if (type === "to") {
      setToDate(formattedDate);
    }
  };

  const listAllDates = () => {
    const start = moment(fromDate, "DD-MM-YYYY");
    const end = moment(toDate, "DD-MM-YYYY");
    const dateList = [];

    // Include the start date
    let currentDate = start.clone();
    console.log("____" + parseInt(daysOnce));
    while (currentDate.isSameOrBefore(end, "day")) {
      if (selectedSubscription === "daily") {
        dateList.push(currentDate.format("DD-MM-YYYY"));
        currentDate.add(1, "day");
      } else if (selectedSubscription === "weekly") {
        console.log("come------->");
        if (currentDate.day() === 5) {
          // Check if the current day is Friday (0 = Sunday, 1 = Monday, ..., 5 = Friday)
          dateList.push(currentDate.format("DD-MM-YYYY"));
        }
        currentDate.add(1, "day");
      } else if (selectedSubscription === "routinely") {
        dateList.push(currentDate.format("DD-MM-YYYY"));
        currentDate.add(parseInt(daysOnce), "day");
      }
    }
    setBetweenDates(dateList);
  };

  useEffect(() => {
    console.log("fromDate:", fromDate);
    console.log("toDate:", toDate);
    if (fromDate && toDate) {
      listAllDates();
    }
  }, [fromDate, toDate]);

  const handleTimeSlotChange = (value) => {
    console.log(value);
    setSelectedTimeSlot(value);
  };
  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().endOf("day");
  };

  const handleDaysOnceChange = (value) => {
    setDaysOnce(value + 1); // Use value directly from InputNumber
  };

  const CustomDatePicker = ({ onChange, disabledDate, id }) => (
    <DatePicker
      onChange={onChange}
      disabledDate={disabledDate}
      id={id}
      inputReadOnly={true}
      style={{ width: "100%" }}
    />
  );

  return (
    <div className="cart-page">
      <div className="cart-items">
        <h2>Cart Items</h2>
        {selectedProducts.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <ul className="cart-items-list">
            {selectedProducts.map((product) => (
              <li key={product.product_id}>
                <img src={product.product_image} alt={product.product_name} />
                <div>
                  <h3>
                    {product.variations.length > 0
                      ? product.variation_name
                      : product.product_name}
                  </h3>
                  {/* <p>Price: {product.price}</p> */}
                  <p>
                    Price:{" "}
                    {product.variations.length > 0
                      ? product.variation_price
                      : product.product_price}
                  </p>
                  <button
                    onClick={() => handleRemoveFromCart(product.product_id)}
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className="total-amount-container">
          <span>Total Amount:</span>
          <span className="total-amount-value">{totalAmount}</span>
        </div>
      </div>
      <div className="checkout-form">
        <h2>Checkout</h2>
        <form onSubmit={startPayment}>
          <div className="form-group">
            <label htmlFor="firstName">First Name*:</label>
            {/* <input type="text" id="firstName" name="firstName" required /> */}
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => updateFormData("firstName", e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name*:</label>
            {/* <input type="text" id="lastName" name="lastName" required /> */}
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => updateFormData("lastName", e.target.value)}
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="companyName">Company Name:</label>
            <input type="text" id="companyName" name="companyName" />
          </div> */}

          <div className="form-group">
            <label htmlFor="streetAddress">Street Address*:</label>
            {/* <input type="text" id="streetAddress" name="streetAddress" /> */}
            <input
              type="text"
              id="streetAddress"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={(e) => updateFormData("streetAddress", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City*:</label>
            {/* <input type="text" id="city" name="city" /> */}
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={(e) => updateFormData("city", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State*:</label>
            {/* <input type="text" id="state" name="state" /> */}
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={(e) => updateFormData("state", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="postcode">Postcode*:</label>
            {/* <input type="text" id="postcode" name="postcode" /> */}
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={formData.postcode}
              onChange={(e) => updateFormData("postcode", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country*:</label>
            {/* <input type="text" id="country" name="country" /> */}
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={(e) => updateFormData("country", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number*:</label>
            {/* <input type="tel" id="phoneNumber" name="phoneNumber" /> */}
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={(e) => updateFormData("phoneNumber", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email*:</label>
            {/* <input type="email" id="email" name="email" required /> */}
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={(e) => updateFormData("email", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="orderNotes">Order Notes:</label>
            {/* <textarea id="orderNotes" name="orderNotes" /> */}
            <input
              type="textarea"
              id="orderNotes"
              name="orderNotes"
              value={formData.orderNotes}
              onChange={(e) => updateFormData("orderNotes", e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="subscription">Subscription*:</label>
            <div className="subscription-options">
              <button
                type="button"
                name="subscription"
                value="daily"
                onClick={() => handleSubscriptionSelect("daily")}
                className={selectedSubscription === "daily" ? "active" : ""}
              >
                Daily
              </button>
              <button
                type="button"
                name="subscription"
                value="weekly"
                onClick={() => handleSubscriptionSelect("weekly")}
                className={selectedSubscription === "weekly" ? "active" : ""}
              >
                Weekly
              </button>

              <button
                type="button"
                name="subscription"
                value="routinely"
                onClick={() => handleSubscriptionSelect("routinely")}
                className={selectedSubscription === "routinely" ? "active" : ""}
              >
                Routinely
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="subscription">Payment Mode*:</label>
            <div className="subscription-options">
              <button
                type="button"
                name="payment"
                value="daily"
                onClick={() => setSelectedPayment("cod")}
                className={selectedPayment === "cod" ? "active" : ""}
              >
                Cash on Delivery
              </button>
              <button
                type="button"
                name="payment"
                value="weekly"
                onClick={() => setSelectedPayment("online")}
                className={selectedPayment === "online" ? "active" : ""}
              >
                Online
              </button>
            </div>
          </div>
          <button type="submit">Place Order</button>
        </form>
      </div>
      <Modal
        title="Subscription"
        visible={showModal}
        onCancel={toggleModal}
        width={getModalWidth()}
        footer={[
          <Button key="cancel" onClick={toggleModal} style={{ height: 40 }}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={toggleModal}
            style={{ height: 40 }}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="date-time-container">
          {selectedSubscription === "weekly" ? (
            <div className="time-slot-container">
              <label htmlFor="timeSlot">Days:</label>
              <Select
                // defaultValue={selectedDay}
                // placeholder="Select Day"
                // style={{ width: "80%" }}
                // onChange={handleChange}
                id="weeklyDay"
                defaultValue={selectedDay}
                placeholder="Select Day"
                style={{ width: "100%" }}
                onChange={handleChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: "none" }}
              >
                {daysOfWeek.map((day) => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
            </div>
          ) : selectedSubscription === "routinely" ? (
            <div className="time-slot-container">
              <label htmlFor="timeSlot">Days Once:</label>
              <InputNumber
                // type="text"
                // id="timeSlot"
                // placeholder="Enter you days once"
                // style={{ width: "80%" }}
                // // onChange={(e) => setDaysOnce(e.target.value)}
                // onChange={handleDaysOnceChange}
                id="daysOnce"
                placeholder="Enter days once"
                style={{ width: "100%" }}
                onChange={handleDaysOnceChange}
                // onFocus={(e) => e.target.blur()}
                min={1}
              />
            </div>
          ) : null}
          <div className="date-picker-container">
            <label htmlFor="fromDate">From Date:</label>
            <DatePicker
              id="fromDate"
              onChange={(date) => handleDateChange(date, "from")}
              disabledDate={disabledDate}
              inputReadOnly={true}
              style={{ width: "100%", fontSize: "16px" }}
              allowClear={true}
              value={beforefromDate} // Ensure `moment` if using moment.js
            />
          </div>
          <div className="date-picker-container">
            <label htmlFor="toDate">To Date:</label>
            <DatePicker
              id="toDate"
              onChange={(date) => handleDateChange(date, "to")}
              disabledDate={disabledDate}
              inputReadOnly={true}
              style={{ width: "100%", fontSize: "16px" }}
              allowClear={true}
              value={beforetoDate}
            />
          </div>

          <div className="time-slot-container">
            <label htmlFor="timeSlot">Time Slot:</label>
            <Select
              // id="timeSlot"
              // placeholder="Select time slot"
              // onChange={handleTimeSlotChange}
              // style={{ width: "100%" }}
              id="timeSlot"
              placeholder="Select time slot"
              onChange={handleTimeSlotChange}
              style={{ width: "100%" }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: "none" }}
              // value={selectedTimeSlot}
            >
              {deliverySlots.map((slot) => (
                <Option key={slot.id} value={`${slot.from} to ${slot.to}`}>
                  {slot.from} to {slot.to}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CartPage;
