// import React, { useState, useEffect, useContext } from "react";
// import "../Styles/product.css";
// import { ProductContext } from "../Context/cartContext";

// function ProductList() {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { selectedProducts, setSelectedProducts } = useContext(ProductContext);
//   const [productPrices, setProductPrices] = useState({});
//   const [selectedVariations, setSelectedVariations] = useState({});
//   const [searchTerm, setSearchTerm] = useState(""); // State for search term

//   const handleSubscribe = (product) => {
//     const index = selectedProducts.findIndex((p) => p.id === product.id);
//     if (index !== -1) {
//       setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
//     } else {
//       const selectedVariation = selectedVariations[product.id] || {};
//       const variationName = Object.values(selectedVariation).join(" - ");
//       const subscribeProduct = {
//         ...product,
//         price: productPrices[product.id] || product.price,
//         variationName: variationName,
//         fullName: `${product.name} - ${variationName}`,
//       };
//       setSelectedProducts([...selectedProducts, subscribeProduct]);
//     }
//   };

//   const handleVariationChange = (
//     productId,
//     attributeId,
//     selectedOption,
//     basePrice
//   ) => {
//     const product = products.find((p) => p.id === productId);
//     const attribute = product.attributes.find(
//       (attr) => attr.id === attributeId
//     );
//     const optionIndex = attribute.options.indexOf(selectedOption);
//     const multiplier = optionIndex + 1;
//     const newPrice = (parseFloat(basePrice) * multiplier).toFixed(2);
//     setProductPrices((prev) => ({ ...prev, [productId]: newPrice }));

//     setSelectedVariations((prev) => ({
//       ...prev,
//       [productId]: {
//         ...prev[productId],
//         [attribute.name]: selectedOption,
//       },
//     }));
//   };

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         let allProducts = [];
//         let page = 1;
//         let totalPages = 1;

//         while (page <= totalPages) {
//           const response = await fetch(
//             `https://vayalvandi.com/wp-json/wc/v3/products?page=${page}&per_page=100`,
//             {
//               method: "GET",
//               headers: {
//                 Authorization:
//                   "Basic " +
//                   btoa(
//                     "ck_a3bcd3751afb86fe063b72f8dada8c023439ee46:cs_63ee27d359499a0a42cca24634a09aabfe7c22af"
//                   ),
//                 "Content-Type": "application/json",
//               },
//             }
//           );
//           if (!response.ok) {
//             throw new Error("Network response was not ok");
//           }
//           const data = await response.json();
//           totalPages = Number(response.headers.get("X-WP-TotalPages"));
//           page++;

//           // Check for duplicates and add only new products
//           const uniqueProducts = data.filter(
//             (product) => !allProducts.some((p) => p.id === product.id)
//           );

//           allProducts = [...allProducts, ...uniqueProducts];
//         }

//         console.log(allProducts);
//         setProducts(allProducts);

//         const initialPrices = {};
//         const initialVariations = {};
//         allProducts.forEach((product) => {
//           initialPrices[product.id] = product.price;
//           initialVariations[product.id] = {};
//           product.attributes.forEach((attr) => {
//             initialVariations[product.id][attr.name] = attr.options[0];
//           });
//         });
//         setProductPrices(initialPrices);
//         setSelectedVariations(initialVariations);

//         setLoading(false);
//       } catch (error) {
//         console.error("There was a problem fetching the products:", error);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, []);

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   // Filter products based on search term
//   const filteredProducts = products.filter((product) =>
//     product.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="product-list">
//       <div className="search-container">
//         <input
//           type="text"
//           placeholder="Search by product name..."
//           value={searchTerm}
//           onChange={handleSearchChange}
//           style={{
//             padding: "10px",
//             fontSize: "16px",
//             border: "1px solid #ccc",
//             borderRadius: "4px",
//             marginBottom: window.innerWidth <= 768 ? "10px" : "20px",
//             width: "100%",
//             boxSizing: "border-box",
//             marginTop: window.innerWidth <= 768 ? "20px" : "-10px",
//           }}
//         />
//       </div>
//       {loading ? (
//         <div className="spinner-container">
//           <div className="spinner"></div>
//         </div>
//       ) : (
//         <div className="card-container">
//           {filteredProducts.map((product) => (
//             <div key={product.id} className="card">
//               {product.images && product.images.length > 0 && (
//                 <img src={product.images[0].src} alt={product.name} />
//               )}
//               <h3>{product.name}</h3>
//               <p>Price: ₹{productPrices[product.id] || product.price}</p>
//               {product.attributes &&
//                 product.attributes.map((attribute) => (
//                   <div key={attribute.id} className="variation-container">
//                     <label htmlFor={`variation-${product.id}-${attribute.id}`}>
//                       {attribute.name}:
//                     </label>
//                     <select
//                       id={`variation-${product.id}-${attribute.id}`}
//                       value={
//                         selectedVariations[product.id]?.[attribute.name] || ""
//                       }
//                       onChange={(e) =>
//                         handleVariationChange(
//                           product.id,
//                           attribute.id,
//                           e.target.value,
//                           product.price
//                         )
//                       }
//                     >
//                       {attribute.options.map((option, index) => (
//                         <option key={index} value={option}>
//                           {option}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 ))}
//               {product.stock_status === "outofstock" && (
//                 <p className="out-of-stock-label">Out of Stock</p>
//               )}
//               <button
//                 onClick={() => handleSubscribe(product)}
//                 className={
//                   selectedProducts.find((p) => p.id === product.id)
//                     ? "Subscribed"
//                     : ""
//                 }
//                 disabled={product.stock_status === "outofstock"}
//               >
//                 {selectedProducts.find((p) => p.id === product.id)
//                   ? "Subscribed"
//                   : "Subscribe"}
//               </button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default ProductList;

import React, { useState, useEffect, useContext } from "react";
import "../Styles/product.css";
import { ProductContext } from "../Context/cartContext";
import axios from "axios";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProducts, setSelectedProducts } = useContext(ProductContext);
  const [productPrices, setProductPrices] = useState({});
  const [selectedVariations, setSelectedVariations] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubscribe = (product, variation) => {
    const productWithVariation = variation
      ? { ...product, ...variation }
      : { ...product, variation_id: product.product_id };
    const index = selectedProducts.findIndex(
      (p) => p.variation_id === productWithVariation.variation_id
    );
    if (index !== -1) {
      setSelectedProducts(
        selectedProducts.filter(
          (p) => p.variation_id !== productWithVariation.variation_id
        )
      );
    } else {
      setSelectedProducts([...selectedProducts, productWithVariation]);
    }
  };

  const handleVariationChange = (productId, variationId) => {
    const product = products.find((p) => p.product_id === productId);
    const variation = product.variations.find(
      (v) => v.variation_id === variationId
    );
    setSelectedVariations((prev) => ({ ...prev, [productId]: variation }));
    setProductPrices((prev) => ({
      ...prev,
      [productId]: variation.variation_price,
    }));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://app-apm7oekwpq-uc.a.run.app/api/products"
        );
        const data = response.data.product_list;

        console.log("API Response Data:", data);

        if (Array.isArray(data)) {
          const initialPrices = {};
          const initialVariations = {};
          data.forEach((product) => {
            initialPrices[product.product_id] = product.product_price;
            if (product.variations.length > 0) {
              initialVariations[product.product_id] = product.variations[0];
            }
          });
          setProducts(data);
          setProductPrices(initialPrices);
          setSelectedVariations(initialVariations);
        } else {
          console.error("Expected an array of products, but received:", data);
        }
        setLoading(false);
      } catch (error) {
        console.error("There was a problem fetching the products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getTextAfterDash = (text) => {
    const parts = text.split(" - ");
    return parts.length > 1 ? parts.slice(1).join(" - ") : text;
  };

  const filteredProducts = products.filter((product) =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="product-list">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by product name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginBottom: window.innerWidth <= 768 ? "10px" : "20px",
            width: "100%",
            boxSizing: "border-box",
            marginTop: window.innerWidth <= 768 ? "20px" : "-10px",
          }}
        />
      </div>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="card-container">
          {filteredProducts.map((product) => (
            <div key={product.product_id} className="card">
              <img
                src={product.product_image}
                // src={
                //   "https://vayalvandi.com/wp-content/uploads/2024/04/Kovakkai.png"
                // }
                alt={product.product_name}
                style={{ width: "100px", height: "100px" }}
              />
              <h3>{product.product_name}</h3>
              <p>
                Price: ₹
                {productPrices[product.product_id] || product.product_price}
              </p>
              {product.variations && product.variations.length > 0 ? (
                <div className="variation-container">
                  <label htmlFor={`variation-${product.product_id}`}>
                    Select Variation:
                  </label>
                  <select
                    id={`variation-${product.product_id}`}
                    value={
                      selectedVariations[product.product_id]?.variation_id || ""
                    }
                    onChange={(e) =>
                      handleVariationChange(
                        product.product_id,
                        parseInt(e.target.value, 10)
                      )
                    }
                  >
                    {product.variations.map((variation) => (
                      <option
                        key={variation.variation_id}
                        value={variation.variation_id}
                      >
                        {getTextAfterDash(variation.variation_name)}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="no-variation-container">
                  {/* <p>No variations available</p> */}
                </div>
              )}
              {product.stock_status === "outofstock" && (
                <p className="out-of-stock-label">Out of Stock</p>
              )}
              <button
                onClick={() =>
                  handleSubscribe(
                    product,
                    selectedVariations[product.product_id]
                  )
                }
                className={
                  selectedProducts.find(
                    (p) =>
                      p.variation_id ===
                      (selectedVariations[product.product_id]?.variation_id ||
                        product.product_id)
                  )
                    ? "Subscribed"
                    : ""
                }
                disabled={product.stock_status === "outofstock"}
              >
                {selectedProducts.find(
                  (p) =>
                    p.variation_id ===
                    (selectedVariations[product.product_id]?.variation_id ||
                      product.product_id)
                )
                  ? "Subscribed"
                  : "Subscribe"}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProductList;
