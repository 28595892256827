// import React, { useContext } from "react";
// import { FaShoppingCart } from "react-icons/fa";
// import "../Styles/Header.css"; // Assuming you have a CSS file for styling
// import { ProductContext } from "../Context/cartContext"; // Import the ProductContext
// import { useNavigate } from "react-router-dom"; // Import useNavigate hook
// import Logo from "../Images/logo.webp";

// function Header() {
//   const navigate = useNavigate(); // Get the navigate function

//   const { selectedProducts } = useContext(ProductContext); // Get selectedProducts from context

//   const navigateToCart = () => {
//     navigate("/cart"); // Navigate to the cart page
//   };

//   return (
//     <div className="header">
//       <div className="header-logo" onClick={() => navigate("/")}>
//         <img src={Logo} alt="Logo" />
//       </div>
//       <a>Home</a>
//       <div className="header-title">vayalVandii Subscription</div>
//       <div className="header-cart" onClick={navigateToCart}>
//         {" "}
//         {/* Call navigateToCart on click */}
//         <FaShoppingCart style={{ color: "white" }} />
//         <span className="cart-count">{selectedProducts.length}</span>{" "}
//         {/* Display the cart count */}
//       </div>
//     </div>
//   );
// }

// export default Header;

import React, { useContext } from "react";
import { FaShoppingCart } from "react-icons/fa";
import "../Styles/Header.css"; // Assuming you have a CSS file for styling
import { ProductContext } from "../Context/cartContext"; // Import the ProductContext
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Logo from "../Images/logo.webp";

function Header() {
  const navigate = useNavigate(); // Get the navigate function

  const { selectedProducts } = useContext(ProductContext); // Get selectedProducts from context

  const navigateToCart = () => {
    navigate("/cart"); // Navigate to the cart page
  };

  const redirectToHome = () => {
    window.location.href = "https://vayalvandi.com/";
  };

  return (
    <div className="header">
      <div className="header-logo-home">
        <div className="header-logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" />
        </div>
        <a className="header-home" onClick={redirectToHome}>
          Home
        </a>
      </div>
      <div className="header-title">vayalVandii Subscription</div>
      <div className="header-cart" onClick={navigateToCart}>
        <FaShoppingCart style={{ color: "white" }} />
        <span className="cart-count">{selectedProducts.length}</span>
      </div>
    </div>
  );
}

export default Header;
