import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

firebase.initializeApp({
  apiKey: "AIzaSyBVuHmzmeoBMQhipQi6-0-CdxQ_M0EV004",
  authDomain: "vayalvandi.firebaseapp.com",
  projectId: "vayalvandi",
  storageBucket: "vayalvandi.appspot.com",
  messagingSenderId: "674934492209",
  appId: "1:674934492209:web:3f70f7f1e918a85e6d9910",
  measurementId: "G-9TF4NKPXJ4",
});

const db = firebase.firestore();
const storage = firebase.storage();

export { db, storage, firebase };
