import React, { createContext, useState } from "react";

// Create a new context for the cart
export const ProductContext = createContext();

// Create a provider component for the cart context
export const ProductProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  // Function to remove a product from the cart
  const removeProductFromCart = (productId) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.product_id !== productId)
    );
  };

  return (
    <ProductContext.Provider
      value={{ selectedProducts, setSelectedProducts, removeProductFromCart }}
    >
      {children}
    </ProductContext.Provider>
  );
};
